.white {
    color: white
  }
  .wrapper {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}