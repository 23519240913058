.ais-SearchBox-submit {
  margin-left: 8px;
  margin-bottom: 8px;
}

a:link {
  text-decoration: none;
}

a:visited {
  color: gray !important;
  text-decoration: none;
}

a:hover {
  color: gray !important;
  text-decoration: underline;
  cursor: pointer; 
}

a:active {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
}

td, th {
  padding: 8px;
  border: 1px solid black;
}
