* {
    margin: 0;
    padding: 0;
  }
body {
    height: 100vh;
    width: 100vw;
    
}
.bottomspacer {
    padding:2em;
}
.privacy {
    /* width: 80%; */
    
    background-color: white;
    
    padding: 4em;
    height: auto;
    border-radius: 1em;
}
.privacypagewrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
    background-image: url('../../public/.well-known/images/bg.jpg');
}
.privacywrapper {
    padding: 1em;
    max-width: 800px;
    margin: auto;
    margin-top: 4em;
    margin-bottom: 4em;
    height: auto;
}
.privacycontent {
    width: 80%;
    max-width: 500px;
    background-color: white;
    margin: auto;
    margin-top: 5em;
}
.logo {
    zoom: 50%;
    width: 100%;
}
.title {
    font-size: 26px;
    font-weight: bold;
}
