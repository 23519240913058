* {
    margin: 0;
    padding: 0;
  }
.wrapper {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    text-align: center;
}
.logo {
    zoom: 50%;
    width: 100%;
}
