a:link {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

a:visited {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer; 
}

a:active {
  color: black;
  text-decoration: underline;
}

.white {
  color: white
}

#root, body, html, #root > div {
  margin: 0;
  height: 100%;
}
.wrapper {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

